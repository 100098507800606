<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h1 class="text-primary">Detail Premium Offer</h1>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0">
                  <div class="card-header bg-primary">
                    <h5>
                      General
                    </h5>
                  </div>
                  <div class="card-body row">
                    <div class="col-sm-8">
                      <div class="form-group row">
                        <label class="col-sm-2">Name</label>
                        <label class="col-sm-1">:</label>
                        <div class="col-sm-9">
                          <strong>{{ item?.name }}</strong>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-2">Offer Type</label>
                        <label class="col-sm-1">:</label>
                        <div class="col-sm-9">
                          {{ getOfferType(item?.offer_type_id) }}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-2">Offer Status</label>
                        <label class="col-sm-1">:</label>
                        <div class="col-sm-9">
                          {{ getOfferStatus(item?.offer_status) }}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-2">Free</label>
                        <label class="col-sm-1">:</label>
                        <div class="col-sm-9">
                          <div v-if="item?.is_free" class="badge badge-info">
                            YES
                          </div>
                          <div v-else class="badge badge-danger">
                            NO
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-2">Web Price IDR</label>
                        <label class="col-sm-1">:</label>
                        <div class="col-sm-9">
                          {{ numberFormatIdr(item?.price_idr) }}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-2">Web Price USD</label>
                        <label class="col-sm-1">:</label>
                        <div class="col-sm-9">
                          {{ numberFormatUsd(item?.price_usd) }}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-2">Point</label>
                        <label class="col-sm-1">:</label>
                        <div class="col-sm-9">
                          {{ formatNumber(item?.price_point) }}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-2">Status Active</label>
                        <label class="col-sm-1">:</label>
                        <div class="col-sm-9">
                          <div v-if="item?.is_active" class="badge badge-info">
                            active
                          </div>
                          <div v-else class="badge badge-danger">
                            inactive
                          </div>
                        </div>
                      </div>
                      <div class="form-group row font-italic">
                        <label class="col-sm-2">created</label>
                        <label class="col-sm-1">:</label>
                        <div class="col-sm-9">
                          {{ formatDate(item?.created) }}
                        </div>
                      </div>
                      <div class="form-group row font-italic">
                        <label class="col-sm-2">modified</label>
                        <label class="col-sm-1">:</label>
                        <div class="col-sm-9">
                          {{ formatDate(item?.modified) }}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <b-card-img
                        v-if="item?.image_normal"
                        title="Premium Offers"
                        :src="imageURL + item.image_normal"
                        class="img-sm text-center rounded cover m-2"
                      ></b-card-img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0">
                  <div class="card-header bg-primary">
                    <h5>
                      Discounts
                    </h5>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12 p-3">
                      <b-table
                        id="table-transition"
                        show-empty
                        :fields="headDiscount"
                        hover
                        responsive="xl"
                        :items="items"
                        sort-icon-left
                      >
                        <template #cell(No)="row">
                          {{ row.index + 1 }}.
                        </template>
                        <template #cell(name)="row">
                          <router-link
                            :to="{
                              name: DiscountDetailPath.name,
                              params: { id: row.item.id },
                            }"
                          >
                            {{ row.item.name }}
                          </router-link>
                        </template>
                        <template #cell(priceIdr)="row">
                          {{
                            getDiscountPrice(
                              row.item.discount_rule,
                              row.item.discount_idr,
                              'IDR',
                            )
                          }}
                        </template>
                        <template #cell(priceUsd)="row">
                          {{
                            getDiscountPrice(
                              row.item.discount_rule,
                              row.item.discount_usd,
                              'USD',
                            )
                          }}
                        </template>
                        <template #cell(pricePoint)="row">
                          {{ formatNumber(row.item.discount_point) }}
                        </template>
                        <template #cell(validFrom)="row">
                          {{ formatDate(row.item.valid_from) }}
                        </template>
                        <template #cell(validTo)="row">
                          {{ formatDate(row.item.valid_to) }}
                        </template>
                        <template #cell(created)="row">
                          {{ formatDate(row.item.created) }}
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0">
                  <div class="card-header bg-primary">
                    <h5>
                      Platforms
                    </h5>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12 p-3">
                      <b-table
                        id="table-transition"
                        show-empty
                        :fields="headPlatform"
                        hover
                        responsive="xl"
                        :items="item?.platform_offers"
                        sort-icon-left
                      >
                        <template #cell(No)="row">
                          {{ row.index + 1 }}.
                        </template>
                        <template #cell(platform)="row">
                          {{ getPlatform(row.item.platform_id) }}
                        </template>
                        <template #cell(priceIdr)="row">
                          {{ numberFormatIdr(row.item.price_idr) }}
                        </template>
                        <template #cell(priceUsd)="row">
                          {{ numberFormatUsd(row.item.price_usd) }}
                        </template>
                        <template #cell(pricePoint)="row">
                          {{ formatNumber(row.item.price_point) }}
                        </template>
                        <template #cell(tier)="row">
                          {{ row.item.tier_code }}
                        </template>
                        <template #cell(created)="row">
                          {{ formatDate(row.item.created) }}
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import constant from '../../store/constant';
import { DiscountDetailPath } from '../../router/marketing';
import moment from 'moment';
export default {
  name: 'PremiumOfferDetail',
  data() {
    return {
      imageURL: constant.IMG_STATIC_URL.URL,
      DiscountDetailPath,
      headDiscount: [
        { key: 'No', label: 'No', thClass: 'bg-secondary' },
        { key: 'name', label: 'Name', thClass: 'bg-secondary' },
        { key: 'priceIdr', label: 'Price IDR', thClass: 'bg-secondary' },
        { key: 'priceUsd', label: 'Price USD', thClass: 'bg-secondary' },
        { key: 'pricePoint', label: 'Price Poin', thClass: 'bg-secondary' },
        { key: 'validFrom', label: 'Valid From', thClass: 'bg-secondary' },
        { key: 'validTo', label: 'Valid To', thClass: 'bg-secondary' },
        { key: 'created', label: 'Created', thClass: 'bg-secondary' },
      ],
      headPlatform: [
        { key: 'No', label: 'No', thClass: 'bg-secondary' },
        { key: 'platform', label: 'Platform', thClass: 'bg-secondary' },
        { key: 'priceIdr', label: 'Price IDR', thClass: 'bg-secondary' },
        { key: 'priceUsd', label: 'Price USD', thClass: 'bg-secondary' },
        { key: 'pricePoint', label: 'Price Point', thClass: 'bg-secondary' },
        { key: 'tier', label: 'Tier Code', thClass: 'bg-secondary' },
        { key: 'created', label: 'Created', thClass: 'bg-secondary' },
      ],
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.offerspremium.isError,
      isLoading: (state) => state.offerspremium.isLoading,
      item: (state) => state.offerspremium.offer,
      items: (state) => state.discounts.items,
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Detail Premium Offer | Content Management System Ebooks Gramedia.com';
      },
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something Went Wrong. Please Try Again', 'error');
    },
    item: function() {
      const payload = {
        id: this.item.discount_id
          ? this.item.discount_id.map((val) => val)
          : '',
      };
      if (this.item.discount_id) this.fetchDiscountOffer(payload);
      payload.id = this.item.discount_id
        ? this.item.discount_id.map((val) => `${val}`)
        : '';
      this.setQuery(payload);
    },
  },
  created: function() {
    const offerID = this.$route.params.id;
    this.fetchPremiumOffersById(offerID);
  },

  methods: {
    ...mapActions('offerspremium', ['fetchPremiumOffersById']),
    ...mapActions('discounts', ['fetchDiscountOffer']),

    setQuery(q = {}) {
      this.$router.push({ query: q }).catch(() => {});
    },

    formatDate(tgl) {
      return moment(tgl).format('YYYY-MM-DD HH:mm:ss');
    },

    formatNumber(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    getOfferType(id) {
      const offerType = Object.keys(constant.OFFER_TYPE).find(
        (key) => constant.OFFER_TYPE[key] === id,
      );
      return offerType;
    },
    getOfferStatus(id) {
      const offerStatus = Object.keys(constant.OFFER_STATUS).find(
        (key) => constant.OFFER_STATUS[key] === id,
      );
      return offerStatus?.replace(/_/g, ' ');
    },
    getPlatform(id) {
      const platform = Object.keys(constant.PLATFORM).find(
        (key) => constant.PLATFORM[key] === id,
      );
      return platform?.replace(/_/g, ' ');
    },

    numberFormatIdr(money) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(money);
    },
    numberFormatUsd(money) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(money);
    },

    getDiscountPrice(rule, amount, currency) {
      if (rule == constant.OFFER_DISCOUNT_RULE['Cut by Percentage.']) {
        return `${amount} %`;
      }
      if (currency.toUpperCase() == 'IDR') {
        return this.numberFormatIdr(amount);
      }
      return this.numberFormatUsd(amount);
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
